<template>
	<section class="content">
		<div st class="modal fade" tabindex="100" role="dialog" ref="formOTP" data-backdrop="false" id = 'otpModal' data-keyboard="false">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<button type="button" class="back" data-dismiss="modal" aria-label="Back" @click="backPin" v-if="!hideBtnClose">
							<span aria-hidden="true">&lt;</span>
						</button>
						<button  type="button" class="close" data-dismiss="modal" aria-label="Close" @click="backPin" v-if="!hideBtnClose">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<!-- <div class="modal-body text-center" v-if="!issetPin">
						<div class="form-group">
							<img src="@/assets/img/pin.svg" class="mb-3" />
							<h5>Atur PIN otorisasi kamu agar dapat<br>melanjutkan proses tambah<br>rekening</h5>
						</div>
						<button @click="showSetCode()" class="btn btn-block btn-lg btn-primary">Atur PIN otorisasi</button>
					</div> -->
					
					<div class="modal-body text-center" v-if="issetPin">
						<div class="alert alert-danger alert-sm" v-if="!pinCheck">{{pinMessage}}</div>
						<div class="mb-4" style="font-style: normal;font-weight: 600;font-size: 16px;line-height: 20px;">Masukkan Kode OTP </div>
						<div style="font-style: normal;font-weight: 400;font-size: 14px;line-height: 20px;">Masukan 4 digit kode OTP yang dikirim melalui</div>
						<div style="font-style: normal;font-weight: 400;font-size: 14px;line-height: 20px;">WhatsApp <span style="color:#FF7203">{{phoneNumber}}</span> </div>
						<div class="row justify-content-center">
							<div class="col-auto"><div :class="(pinSet.substr(0,1) && pinStar[0] ? 'h4 py-2' : ((pinSet.substr(0,1) ? 'text-primary' : 'text-light') + ' h1 py-0 font-weight-bold')) + ' border-bottom border-primary px-2'">{{pinSet.substr(0,1) == '' || !pinStar[0] ? '&bull;' : pinSet.substr(0,1)}}</div></div>
							<div class="col-auto"><div :class="(pinSet.substr(1,1) && pinStar[1] ? 'h4 py-2' : ((pinSet.substr(1,1) ? 'text-primary' : 'text-light') + ' h1 py-0 font-weight-bold')) + ' border-bottom border-primary px-2'">{{pinSet.substr(1,1) == '' || !pinStar[1] ? '&bull;' : pinSet.substr(1,1)}}</div></div>
							<div class="col-auto"><div :class="(pinSet.substr(2,1) && pinStar[2] ? 'h4 py-2' : ((pinSet.substr(2,1) ? 'text-primary' : 'text-light') + ' h1 py-0 font-weight-bold')) + ' border-bottom border-primary px-2'">{{pinSet.substr(2,1) == '' || !pinStar[2] ? '&bull;' : pinSet.substr(2,1)}}</div></div>
							<div class="col-auto"><div :class="(pinSet.substr(3,1) && pinStar[3] ? 'h4 py-2' : ((pinSet.substr(3,1) ? 'text-primary' : 'text-light') + ' h1 py-0 font-weight-bold')) + ' border-bottom border-primary px-2'">{{pinSet.substr(3,1) == '' || !pinStar[3] ? '&bull;' : pinSet.substr(3,1)}}</div></div>
						</div>
						<div class="overlay d-flex justify-content-center align-items-center" v-if="loadingPin"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div><br>
						<!-- <div class="h6 font-weight mt-3">Masukkan 6 digit PIN otorisasi kamu untuk melanjutkan proses {{messagePin}}</div> -->
						<div class="mb-2" v-if="countDown!=0" style="font-style: normal;font-weight: 400;font-size: 12px;line-height: 20px;">Belum dapet kode? Tunggu <span style="color:#FF7203;font-style: normal;font-weight: 400;font-size: 12px;line-height: 20px;">{{ countDown }}</span>  untuk kirim ulang</div>
						<div class="mb-2" v-if="countDown==0"> <span  @click="getCode()" style="color:#FF7203;font-style: normal;font-weight: 400;font-size: 12px;line-height: 20px;">Kirim Ulang</span> </div>
						<div class="m-auto" style="max-width: 400px">
							<div class="row justify-content-center">
								<div class="col-3 btn btn-primary m-1" v-for="index in 9" :key="index" @click="pinKeyPress(index)">
									<span class="h2">{{ index }}</span>
								</div>
								<div class="col-3 btn btn-primary m-1 d-flex justify-content-center align-items-center" @click="pinKeyPress('Clear')">
									<span class="h2 mb-0"><i class="fi fi-rr-arrows-repeat d-flex justify-content-center align-items-center"></i></span>
								</div>
								<div class="col-3 btn btn-primary m-1" @click="pinKeyPress('0')">
									<span class="h2">0</span>
								</div>
								<div class="col-3 btn btn-primary m-1 d-flex justify-content-center align-items-center" @click="pinKeyPress('Backspace')">
									<span class="h2 mb-0"><i class="fi fi-rr-delete d-flex justify-content-center align-items-center"></i></span>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer" hidden></div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import $ from "jquery";
import { authFetch } from "@/libs/hxcore";
import moment from "moment";
import Swal from "sweetalert2";

export default {
	props: {
		handleCode: String,
		code: String,
		params:Object
	},
	data() {
		return {
			issetPin: false,
			pinSet: '',
			shipperPin:'',
			pinStar: [false, false, false, false, false, false],
			pinCheck: true,
			pinMessage: '',
			loadingPin: false,
			phoneNumber: '',
			type:'',
			currentRequest: 0,
			countDown: '0',
			myInterval:undefined,
			hideBtnClose:false,
			pinAuthorization:0
		};
	},
	components: {},
	created() {
		// this.countDownTimer();
	},
	beforeDestroy() {
		// window.removeEventListener('keyup', this.pinKeyPress);
	},
	methods: {
		backPin() {
			this.pinSet = '';
			// window.removeEventListener('keyup', this.pinKeyPress);
			$(this.$refs.formOTP).modal("hide");
			if (!this.issetPin) {
				// this.$router.back();
			}
		},
		pinKeyPress(e) {
			var char = parseInt(e);
			if (Number.isInteger(char) && this.pinSet.length < 4) {
				this.pinStar[this.pinSet.length - 1] = false;
				this.pinStar[this.pinSet.length - 0] = true;
				this.pinSet = this.pinSet + char;
				if (this.pinSet.length > 3) {
					this.submitCode();
				}
			} else if (e == 'Backspace') {
				this.pinSet = this.pinSet.substr(0, this.pinSet.length - 1);
				this.pinStar[this.pinSet.length - 0] = false;
				this.pinStar[this.pinSet.length - 1] = true;
			} else if (e == 'Clear') {
				this.pinSet = '';
			}
		},
		showSetCode() {
			$(this.$refs.formOTP).modal("hide");
			this.$router.push({ path: "/settings/OneTimePassword"});
		},
		checkPin: function () {
			this.loadingPin = true;
			let urlSubmit = "/shipper/valid_pin/" + this.pinSet;

			authFetch(urlSubmit, 
			{
				method: "GET",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				res.json().then((json) =>
				{
					this.pinStar[this.pinSet.length - 1] = false;
					if (json.success) {
						// this.issetPin = false;
						// this.pinSet = '';
						// this.pinCheck = true;
						// this.pinMessage = '';
						// this.$emit("change");
						// // window.removeEventListener('keyup', this.pinKeyPress);
						// $(this.$refs.formOTP).modal("hide");
					} else {
						// this.pinSet = '';
						// this.pinCheck = false;
						// this.pinMessage = json.msg;
					}
					this.loadingPin = false;
				});
			});
		},
		submitCode: function () {
			// this.loadingPin = true;
			this.$emit("change",this.pinSet);
		},
		getCode:function(){
			this.currentRequest += 1;
			if(this.type == 'change_number'){
				this.generateCodeNewNumber();
			}else{
				this.generateCode();
			}
		},
		generateCode: function () {
				this.loadingPin = true;
				// $(this.$refs.formOTP).modal("hide");
				authFetch("/users/One_time_password?type="+this.type+"&pin="+this.pinAuthorization)
				.then((res) => {
					if (res.status === 201) {
					} else if (res.status === 400) {
					}
					return res.json();
				})
				.then((js) => {
					if (js.success) {
						this.issetPin = true;
						clearInterval(this.myInterval)
						this.countDownTimer();
						// window.addEventListener('keyup', this.pinKeyPress);
					}else{
						Swal.fire({
							text: js.message,
							icon: 'error',
							showCloseButton: true
						})
						$(this.$refs.formOTP).modal("hide");
						localStorage.removeItem(`expiredToken-${this.type}`);
						return;
					}
					this.loadingPin = false;
					$(this.$refs.formOTP).modal("show");
				});
		},generateCodeNewNumber: function () {
			this.loadingPin = true;
				$(this.$refs.formOTP).modal("hide");
				authFetch("/users/One_time_password/new_number?type="+this.type+"&number="+this.phoneNumber+"&pin="+this.shipperPin)
				.then((res) => {
					if (res.status === 201) {
					} else if (res.status === 400) {
					}
					return res.json();
				})
				.then((js) => {
					if (js.success) {
						this.issetPin = true;
						clearInterval(this.myInterval)
						this.countDownTimer();
						// window.addEventListener('keyup', this.pinKeyPress);
					}else{
						Swal.fire({
							text: js.message,
							icon: 'error',
							showCloseButton: true
						})
						$(this.$refs.formOTP).modal("hide");
						return;
					}
					this.loadingPin = false;
					$(this.$refs.formOTP).modal("show");
				});
		},
		handleChange (event) {
              this.$emit("customChange", event.target.value.toUpperCase())
        },
		countDownTimer () {
			var eventTime = moment().add(this.currentRequest>2?this.currentRequest*this.currentRequest:2*this.currentRequest, 'minutes').format('X') // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
            var currentTime = moment().format('X');// Timestamp - Sun, 21 Apr 2013 12:30:00 GMT
            var diffTime = parseInt(eventTime) - parseInt(currentTime);
            var duration = moment.duration(diffTime*1000, 'milliseconds')
            var interval = 1000;
            localStorage.setItem(`expiredToken-${this.type}`, moment(eventTime,'X').format('X'));
            this.myInterval = setInterval(() =>{
                duration = moment.duration(  duration  - interval, 'milliseconds');
                this.countDown = moment.utc(duration  - interval).format("mm:ss");
				// console.log(moment.duration( duration, 'milliseconds').minutes(),moment.duration( duration, 'milliseconds').seconds());
                if (moment.duration( duration, 'milliseconds').minutes() == 0 && duration.seconds() == 0){
                    clearInterval(this.myInterval);
                    this.countDown = 0
                }
            }, interval);
		},
		countDownCurrentTimer () {
			var eventTime = moment(localStorage.getItem(`expiredToken-${this.type}`),'X').format('X') // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
            var currentTime = moment().format('X');// Timestamp - Sun, 21 Apr 2013 12:30:00 GMT
            var diffTime = parseInt(eventTime) - parseInt(currentTime);
            var duration = moment.duration(diffTime*1000, 'milliseconds')
            var interval = 1000;
            localStorage.setItem(`expiredToken-${this.type}`, moment(eventTime,'X').format('X'));
            this.myInterval = setInterval(() =>{
                duration = moment.duration(  duration  - interval, 'milliseconds');
                this.countDown = moment.utc(duration  - interval).format("mm:ss");
				// console.log(moment.duration( duration, 'milliseconds').minutes(),moment.duration( duration, 'milliseconds').seconds());
                if (moment.duration( duration, 'milliseconds').minutes() == 0 && duration.seconds() == 0){
                    clearInterval(this.myInterval);
                    this.countDown = 0
                }
            }, interval);
		},
	},
	mounted() {	},
	watch: {
		params: function (old, val) {
			this.pinSet = '';
			this.type = old['otpType'];
			this.phoneNumber = old['phoneNumber'];
			this.currentRequest =  old['countShowOTP'];
			this.shipperPin =  old['shipperPin'];
			this.pinAuthorization =  old['pinAuthorization'];
			if(old['countShowOTP']>0 && old['countShowOTP']!=val['countShowOTP']){
				this.issetPin = true;
        		var timeLive = parseInt(moment().format('X'))  - parseInt(moment(localStorage.getItem(`expiredToken-${this.type}`),'X').format('X'));
        		if(timeLive>0 || !timeLive){
					if(old['otpType'] == 'change_number'){
						this.generateCodeNewNumber();
					}else{
						this.generateCode();
					}
					if(old['otpType'] == 'new_number'){
						this.hideBtnClose = true;
					}else{
						this.hideBtnClose = false;
					}
				}else{
					clearInterval(this.myInterval);
					this.countDownCurrentTimer();
				}
				// this.loadingPin = false;
				$(this.$refs.formOTP).modal("show");
			}else{
				$(this.$refs.formOTP).modal("hide");
			}
		},
	}
};
</script>
<style scoped>
	div.vue-pincode-input-wrapper {
		display: flex;
		justify-content: center;
	}

	input.vue-pincode-input {
		border-bottom: 1px solid orange;
		box-shadow: none !important;
	}

	#otpModal {
  		background: rgba(0, 0, 0, 0.5);
	}
</style>