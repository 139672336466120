const routes = [{
        path: '/riwayat',
        name: 'Riwayat',
        component: () =>
            import ('@/views/Riwayat.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.history'
        }
    },
    {
        path: '/riwayat/all',
        name: 'Riwayat',
        component: () =>
            import ('@/views/Riwayat.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.history'
        }
    },
    {
        path: '/payment',
        name: 'Payment',
        component: () =>
            import ('@/views/Payment.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/cek',
        name: 'Cek Status',
        component: () =>
            import ('@/views/Checktracking.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.tracking'
        }
    },
    {
        path: '/uploader',
        name: 'Buat Kiriman',
        component: () =>
            import ('@/views/Uploader.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/shipper',
        name: 'Shipper',
        component: () =>
            import ('@/views/Shipper.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.list'
        }
    },
    {
        path: '/shipper/detail/:id',
        name: 'Shipper Status',
        component: () =>
            import ('@/views/DetailShipper.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.status'
        }
    },
    {
        path: '/shipper/recap',
        name: 'Shipper Recap',
        component: () =>
            import ('@/views/ShipperRecap.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.list'
        }
    },
    {
        path: '/shipper/rekening',
        name: 'Request Rekening Shipper',
        component: () =>
            import ('@/views/RekeningShipper.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.list'
        }
    },
    {
        path: '/buyer',
        name: 'Buyer',
        component: () =>
            import ('@/views/Buyer.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.admin.buyer'
        }
    },
    {
        path: '/seller',
        name: 'Alamat Pengirim',
        component: () =>
            import ('@/views/Seller.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.seller'
        }
    },
    {
        path: '/order',
        name: 'Order Group',
        component: () =>
            import ('@/views/Order.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/order/count/:id',
        name: 'Detail Order Group',
        component: () =>
            import ('@/views/Ordercount.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/order/create',
        name: 'Membuat Pesanan',
        component: () =>
            import ('@/views/OrderformBatch.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/order/create/single',
        name: 'Buat Pesanan',
        component: () =>
            import ('@/views/mobile/Orderform.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/order/create/draft/:id',
        name: 'Lanjutkan Pesanan',
        component: () =>
            import ('@/views/mobile/Orderform.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    // {
    //     path: '/order/import',
    //     name: 'Import Pesanan',
    //     component: () =>
    //         import ('@/views/Orderimport.vue'),
    //     meta: {
    //         layout: 'AppLayoutAdmin',
    //         requiresAuth: true,
    //         code: 'cod.order.form'
    //     }
    // },
    {
        path: '/order/import',
        name: 'Buat Kiriman Batch',
        component: () =>
            import ('@/views/Orderbatch.vue'),
            // import ('@/views/Orderimport.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/riwayat/draft-kiriman',
        name: 'Buat Kiriman Batch',
        component: () =>
            import ('@/views/RiwayatDraft.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.history.draft'
        }
    },
    {
        path: '/order/edit/:id',
        name: 'Form Edit Order Group',
        component: () =>
            import ('@/views/Orderform.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/orderform',
        name: 'Form Order',
        component: () =>
            import ('@/views/Orderform2.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () =>
            import ('@/views/Profile.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/kustom-kurir',
        name: 'Pengaturan Kurir',
        component: () =>
            import ('@/views/KustomKurir.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/pengumuman',
        name: 'Pengumuman',
        component: () =>
            import ('@/views/Pengumuman.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'public.announcement'
        }
    },
    {
        path: '/register',
        name: 'Registrasi',
        component: () =>
            import ('@/views/Registernew.vue')
    },
    {
        path: '/register/:id',
        name: 'Registrasi',
        component: () =>
            import ('@/views/Registernew.vue')
    },
    {
        path: '/settings/user',
        name: 'Data Admin Shipper',
        component: () =>
            import ('@/views/master/UserCS.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.cs.list'
        }
    },
    {
        path: '/order/detail/:id',
        name: 'Detail Order',
        component: () =>
            import ('@/views/Orderdetail.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/order/edititem/:id',
        name: 'Edit Order',
        component: () =>
            import ('@/views/Orderedit.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/saldo',
        name: 'Saldo',
        component: () =>
            import ('@/views/Saldo.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.saldo.form'
        }
    },
    {
        path: '/saldo/withdraw/retained',
        name: 'Daftar Paket Dana Tertahan',
        component: () =>
            import ('@/views/RiwayatTertahan.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.saldo.form'
        }
    },
    {
        path: '/payment-shipper/all',
        name: 'Payment Shipper',
        component: () =>
            import ('@/views/PaymentShipper.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.shipper'
        }
    },
    {
        path: '/payment-shipper/retur',
        name: 'Ongkir Retur Shipper',
        component: () =>
            import ('@/views/PaymentShipperRetur.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.shipper'
        }
    },
    {
        path: '/saldo/shipper/:id',
        name: 'Rincian Saldo Shipper',
        component: () =>
            import ('@/views/RincianSaldo.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.admin'
        }
    },
    {
        path: '/dashboard/finance',
        name: 'Financial Report',
        component: () =>
            import ('@/views/DashboardShipperFinance.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.dashboard.shipper'
        }
    },
    {
        path: '/dashboard-cs',
        name: 'Dashboard',
        component: () =>
            import ('@/views/DashboardShipperCs.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.dashboard.shipper'
        }
    },
    {
        path: '/settings/rekening',
        name: 'Info Rekening',
        component: () =>
            import ('@/views/Rekening.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.user.rekening'
        }
    },
    {
        path: '/settings/tickets',
        name: 'List Ticket',
        component: () =>
            import ('@/views/ListTicket.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.tickets'
        }
    },
    {
        path: '/settings/tickets/all',
        name: 'List Ticket',
        component: () =>
            import ('@/views/ListTicket.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.tickets'
        }
    },
    {
        path: '/settings/tickets/claim',
        name: 'List Claim',
        component: () =>
            import ('@/views/ListClaim.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.tickets'
        }
    },
    {
        path: '/arsip/klaim',
        name: 'Arsip List Claim',
        component: () =>
            import ('@/views/ArsipListClaim.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.tickets'
        }
    },
    {
        path: '/settings/tickets/claimcourier',
        name: 'List Claim',
        component: () =>
            import ('@/views/ListClaimCourier.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.tickets'
        }
    },
    {
        path: '/cek-ongkir',
        name: 'Cek Ongkir',
        component: () =>
            import ('@/views/CekOngkir.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.ongkir'
        }
    },
    {
        path: '/calculator',
        name: 'Kalkulator Pengiriman',
        component: () =>
            import ('@/views/Calculator.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.ongkir'
        }
    },
    {
        path: '/pages/:id',
        name: 'Informasi',
        component: () =>
            import ('@/views/Informasi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.news'
        }
    },
    {
        path: '/info',
        name: 'Master Info',
        component: () =>
            import ('@/views/master/Info.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.info'
        }
    },
    {
        path: '/info/create',
        name: 'Form Info',
        component: () =>
            import ('@/views/FormInfo.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            // code: 'cod.info'
        }
    },
    {
        path: '/info/:id',
        name: 'Info',
        component: () =>
            import ('@/views/PreviewInfo.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            // code: 'cod.info'
        }
    },
    {
        path: '/info/edit/:id',
        name: 'Master Info',
        component: () =>
            import ('@/views/FormInfo.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            // code: 'cod.info'
        }
    },
    {
        path: '/shipper/verification/:id',
        name: 'Detail Verification KTP',
        component: () =>
            import ('@/views/DetailVerificationKtp.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.verification'
        }
    },
    {
        path: '/verification',
        name: 'Verifikasi Akun',
        component: () =>
            import ('@/views/VerifyShipper.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.verify.shipper'
        }
    },
    {
        path: '/settings/pin',
        name: 'Buat PIN otorisasi',
        component: () =>
            import ('@/views/SetPin.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.pin.shipper'
        }
    },
    {
        path: '/shipper/membership',
        name: 'Membership',
        component: () =>
            import ('@/views/Membership.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.membership'
        }
    },
    {
        path: '/shipper/membership/history',
        name: 'History Membership',
        component: () =>
            import ('@/views/MembershipHistory.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.membership'
        }
    },
    {
        path: '/settings/katalog',
        name: 'Katalog Produk',
        component: () =>
            import ('@/views/Katalog.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/settings/destination',
        name: 'Kode Alamat',
        component: () =>
            import ('@/views/master/Destination.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.courier.destination'
        }
    },
    {
        path: '/afiliasi-shipper-detail/:id',
        name: 'Detail Komisi Affiliator',
        component: () =>
            import ('@/views/ShipperAfiliasiDetail.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.affiliasi'
        }
    },
    {
        path: '/afiliasi-shipper-detail',
        name: 'Program Afiliasi',
        component: () =>
            import ('@/views/ShipperAfiliasiDetail.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.affiliasi'
        }
    },
    {
        path: '/list-notif',
        name: 'Notifikasi',
        component: () =>
            import ('@/views/ListNotifikasi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
        }
    },
    {
        path: '/arsip/riwayat',
        name: 'Arsip Riwayat',
        component: () =>
            import ('@/views/RiwayatArsip.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
        }
    },
    {
        path: '/arsip/detail/:id',
        name: 'Arsip Detail Order ',
        component: () =>
            import ('@/views/OrderdetailArsip.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/arsip/order',
        name: 'Arsip Order Group',
        component: () =>
            import ('@/views/OrderArsip.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/arsip/order/count/:id',
        name: 'Arcive Detail Order Group',
        component: () =>
            import ('@/views/OrdercountArsip.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/arsip/tiket',
        name: 'Arsip Tiket',
        component: () =>
            import ('@/views/ListTicketArsip.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.tickets'
        }
    },
]

export default routes